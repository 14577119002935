<template>
  <div>

    <SendoutTemplateTable
      :items="sendout_templates"
      @create_clicked="create_template_clicked"
      @select_clicked="select_template_clicked"
      @delete_clicked="delete_template_clicked"
    />

    <SendoutTemplateEditorModal
      :item="selected_item"
      ref="template-editor-modal"
      @updated="template_updated"
      @created="template_created"
    />

  </div>
  
</template>


<style lang="scss" scoped>

</style>

<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';

import SendoutTemplateEditorModal from '@/view/pages/ml/sendout_template/SendoutTemplateEditorModal.vue';
import SendoutTemplateTable from '@/view/pages/ml/sendout_template/SendoutTemplateTable.vue';

export default {
  name: 'SendoutTemplateComponent',
  mixins: [ toasts ],
  components: {
    SendoutTemplateEditorModal,
    SendoutTemplateTable,
    TipLabel,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'customer'])
  },
  mounted() {
    this.fetch_sendout_templates();
  },
  data() {
    return {
      selected_item: {},
      sendout_templates: []
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.fetch_sendout_templates();
      }
    },
    currentPeriodId(newValue, oldValue) {
      if (this.currentCompanyId) {
        this.fetch_sendout_templates();
      }
    }
  },
  methods: {

    async fetch_sendout_templates() {
      const res = await axios.get(`/template/sendout/customer/${this.customer.id}`);

      if (res.status === 200) {
        this.sendout_templates = res.data;
      }
    },

    create_template_clicked() {
      this.$refs['template-editor-modal'].show();
    },

    template_created(template) {
      this.sendout_templates.unshift(template);
    },

    select_template_clicked(template) {
      this.selected_item = template;
      this.$refs['template-editor-modal'].show();
    },

    async delete_template_clicked(template) {
      const res = await axios.delete(`/template/sendout/${template.id}`);

      if (res.status === 204) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('FILE.DELETED'));
      }
    },
    
    template_updated(template) {
      const index = this.sendout_templates.findIndex(item => item.id === template.id);

      if (index < 0) {
        return;
      }

      this.sendout_templates[index] = template;

      this.sendout_templates = [ ...this.sendout_templates ];
    },

  }
};
</script>
